<template>
  <b-form-group
    label="Sabit İçerik"
    label-for="itemid"
  >
    <v-select
      id="itemid"
      v-model="itemData.itemid"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      placeholder="Seçiniz"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Contents',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    dataList() {
      return this.$store.getters['contents/dataList']
    },
    itemData() {
      return this.$store.getters['menus/dataItem']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('contents/getDataList')
    },
  },
}
</script>
