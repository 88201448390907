<template>
  <b-form-group
    label="Blog Kategorisi"
    label-for="itemid"
  >
    <v-select
      id="itemid"
      v-model="itemData.itemid"
      :options="dataList"
      label="title"
      :reduce="item => item.id"
      placeholder="Seçiniz"
    >
      <template v-slot:option="option">
        <div>
          {{ option.title }}
        </div>
        <div>
          <small class="text-warning">{{ option.main_categories }}</small>
        </div>
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'BlogCategories',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    dataList() {
      return this.$store.getters['blogCategories/dataList']
    },
    itemData() {
      return this.$store.getters['menus/dataItem']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('blogCategories/getDataList', {
        where: {
          'blog_categories.id_blog_categories !=': null,
        },
      })
    },
  },
}
</script>
