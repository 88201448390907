<template>
  <b-form-group
    label="Özel Bağlantı"
    label-for="external_url"
  >
    <b-form-input
      id="external_url"
      v-model="itemData.external_url"
      placeholder="https://example.com"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'ExternalUrl',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    itemData() {
      return this.$store.getters['menus/dataItem']
    },
  },
}
</script>
